<template>
  <a-modal :title="'查看打款单【'+rowData.collectionCode+'】'" style="top: 8px;" :width="1000" v-model="visible" :footer="null" :maskClosable="false">
    <a-form-model ref="form" :model="rowData" layout="horizontal" :label-col="{span:6}" :wrapper-col="{span:18}">
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="打款单号">
            {{ rowData.collectionCode }}
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="经销商">
            {{ rowData.dealerName }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="大区/事务所">
            {{ rowData.areaName }}/{{rowData.firmName}}
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="业绩归属">
            {{ rowData.belongDealerName }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="负责人">
            {{ rowData.lingganResponsibilityDep }} {{rowData.lingganResponsibilityName}}
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="收款金额">
            {{ rowData.amount ? rowData.amount.toFixed(2) : 0.00 }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="打款类型">
            {{ rowData.paymentType == 1 ? '个人' : '公司' }}
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="金额类型">
            {{rowData.amountType == 1 ? '承兑' : rowData.amountType == 2 ? '电汇' : '--'}}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <!--            <a-col :md="12" v-if="rowData.amountType == 2">
                      <a-form-model-item label="金额类型来源">
                        {{ rowData.amountTypeSource | amountTypeSource }}
                      </a-form-model-item>
                    </a-col>-->
        <a-col :md="12" v-if="rowData.amountType == 1">
          <a-form-model-item label="兑换时长">
            {{ rowData.acceptanceName }}
          </a-form-model-item>
        </a-col>

        <a-col :md="12">
          <a-form-model-item label="用途">
            {{ rowData.topUpPurpose }}
          </a-form-model-item>
        </a-col>

      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="所属渠道">
            {{ rowData.channel == 1 ? '三菱' :  rowData.channel == 2 ? '非三菱' : '--'}}
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="所属分类">
            {{ rowData.categoryName }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="创建人">
            {{ rowData.createUser}}
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="创建时间">
            {{ rowData.createTime }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="备注说明">
            {{rowData.remark}}
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="付款凭证" class="buju" >
            <div v-if="rowData.paymentVoucher == null">暂无</div>
            <QiniuUpload v-else :disabled="true" type="image" :value.sync="rowData.paymentVoucher"></QiniuUpload>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="footer-bts">
      <a-button key="back" @click="handleCancel">关闭</a-button>
    </div>
  </a-modal>

</template>

<script>
import {selectByOrderCodeFinanceReceipt} from '../api/FinanceReceiptApi'
export default {
    data() {
        return {
            visible: false,
            loading: false,
            rowData: {},
        }
    },
    methods: {

      // 关闭弹框
      handleCancel() {
        this.rowData = {}
        this.visible = false
      },
      /**
       * 获取行数据
       */
      setRowData(row){
          this.visible = true
          this.rowData = row
      },
      /**
       * 获取行数据
       */
      setRowDataByCode(collectionCode){
        this.visible = true
        this.rowData = {}
        selectByOrderCodeFinanceReceipt(collectionCode).then(res => {
          this.rowData = res.body
        })
      },

    },
    created() {

    },

}
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.info-item {
  background-color: #f4f4f4;
  padding-left: 10px;
  border-radius: 3px;
  min-height: 40px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
